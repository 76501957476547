@import "../../theme.scss";

.FollowDetail {
    padding: 0 20px;

    .FollowDetail-form {
        margin-top: 30px;
        box-shadow: 4px 4px 41px 1px #0F172614;
        padding: 19px 20px;
        margin-bottom: 28px;
    }

    .FollowDetail-edit {
        width: 100%;
        border-radius: 1000px;
        margin-bottom: 28px;
    }
}