:root {
    // antd
    --adm-center-popup-border-radius: 40px;
    --adm-button-background-color: #2A374C;
    --adm-button-text-color: white;
    --adm-button-border-radius: 12px;
    --border-radius: 40px;
    --adm-color-primary: #CECECE;
    --adm-color-light: --text-color-secondary;
    --font-size: 16px;
    --adm-color-weak: #28303F;
    --adm-color-light: #999;

    .adm-text-area {
        --disabled-color: none;
    }

    // --adm-center-popup-background-color: rgba(0, 0, 0, 0);

    // custom
    --text-color-main: #28303F;
    --text-color-secondary: #9AA3B5;
    --text-color-article: #555F70;
    --text-color-placholder: #999;
}

// .adm-picker-header-button {
//     color: var(--text-color-main);
// }

.adm-input-noBg {
    background: none !important;

    .adm-input-element {
        color: var(--text-color-main) !important;
        --placeholder-color: #878787 !important;
    }
}