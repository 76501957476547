@import "../../theme.scss";

.Chat-to-labor {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 66px;
    z-index: 90;
}

.Chat {

    padding-top: 16px;
    height: 100%;

    .Chat-doc-pic {
        width: 62px;
        height: 62px;
        background-color: #bfbfbd;
        border-radius: 1000px;
        overflow: hidden;
        border: 4px solid white;
        position: absolute;
        top: 40px;
        left: 50%;
        margin-left: -40px;
        z-index: 12;
    }

    .Chat-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .Chat-container {
        overflow: hidden;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        // padding: 19px 20px;
        // padding-top: 50px;
        // padding-bottom: 88px;
        background-color: white;
        position: relative;
        z-index: 10;
        height: calc(100vh - 48px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .Chat-messages {
            flex: 1;
            width: 100%;
        }

        .Chat-illustrate {
            padding: 16px;
            margin-bottom: 20px;
            background: #F5F6F7;
            border-radius: 10px;
            font-family: PingFang SC;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
        }
    }

    .Chat-input-container {
        height: 88px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: white;
        z-index: 11;
        box-shadow: 0px -8px 20px 1px #979CA714;

        .Chat-input {
            width: 312px;
            border: 1px solid #2A374C;
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            border-radius: 12px;
            margin: 0 auto;
            padding: 12px;
            position: relative;
            top: 12px;
        }
    }
}