.FollowDetail-form-item {
    margin-bottom: 19px;

    .FollowDetail-form-item-input {
        padding: 10px 16px;
        width: calc(100% - 30px);
        border-radius: 8px;
        background: #F5F6F7;

        &.disabled {
            background: none;
        }

        .adm-input-element {
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 400;
            // --placeholder-color: var(--text-color-placholder);
        }

        .adm-text-area-element {
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 400;
            // --placeholder-color: var(--text-color-placholder);
        }
    }

    .FollowDetail-form-item-btn {
        margin-left: 10px;
        width: 190px;
    }

    .FollowDetail-form-item-title {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 6px;

        .FollowDetail-form-item-title-require {
            color: red !important;
        }
    }

    .Sms-form-item {
        display: flex;
    }
}