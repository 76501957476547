@import "../../theme.scss";

.Follow {
    padding: 0 20px;

    .Follow-title {
        text-align: center;
        margin-bottom: 36px;
        position: relative;
        margin-top: 16px;

        .Follow-back-btn {
            position: absolute;
            left: 20px;
            width: 32px;
            height: 32px;
        }
    }

    .Follow-steps {
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;

        .Follow-step {
            height: 140px;

            .Follow-step-title {
                color: var(--text-color-main);
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                display: flex;
                justify-content: flex-start;
                align-content: center;
                margin-left: 12px;

                .Follow-step-title-info {
                    font-family: PingFang SC;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-left: 14px;
                }
            }

            .Follow-icon {
                width: 12px;
                height: 12px;
                border-radius: 1000px;
                border: 3px solid var(--text-color-main);
                background: white;
            }

            .Follow-step-content {
                margin-top: 12px;
                margin-left: 65px;

                .Follow-step-btn {
                    padding-left: 25px;
                    padding-right: 25px;
                    border-radius: 1000px;
                    font-family: PingFang SC;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
}