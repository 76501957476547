.title {
    text-align: center;
    position: relative;
    height: 32px;
    z-index: 10;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 600;


    .back-btn {
        position: absolute;
        left: 0;
        width: 54px;
        // height: 32px;
        top: 18px;
    }
}