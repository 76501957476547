@import "../../theme.scss";

.Login {
    position: relative;
    height: 100vh;

    .Login-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
    }

    .Login-welcome {
        position: absolute;
        top: 350px;
        left: 58px;
        color: var(--text-color-main);

        .Login-welcome-hello {
            font-family: PingFang SC;
            font-size: 26px;
            font-weight: 600;
            line-height: 36px;
        }

        .Login-welcome-title {
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            margin-top: 7px;

            a {
                font-weight: 600;
                color: #289FBB;
            }
        }

        .Login-welcome-content {
            margin-top: 36px;
            margin-bottom: 75px;
        }

        .Login-welcome-btn {
            width: 260px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1000px;
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 500;

            .Login-welcome-icon {
                width: 16px;
                height: 16px;
                margin-right: 6px;
                position: relative;
                top: 2px;
            }
        }
    }

    .Login-bindphone {
        padding: 40px;
        background-color: white;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 80px);

        .Login-protocol-switch-container {
            margin-bottom: 80px;
            color: rgb(114, 114, 114);

            a {
                color: #2A374C;
                font-weight: 600;
            }

            .adm-checkbox-content {
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: 400;
            }

            .adm-checkbox-icon {
                border: 1px solid black;
                border-radius: 4px;
            }

            .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
                background-color: black;
            }
        }

        .Login-bind-title {
            font-family: PingFang SC;
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 18px;
        }

        .Login-bind-item {
            // margin-bottom: 104px;
        }

        .Login-bind-btn {
            border-radius: 1000px;
        }
    }

    .Login-banner {
        position: relative;
        top: 20px;

        .Login-doctor-pic {
            width: 248px;
        }

        .Login-doctor-talk {
            position: absolute;
            width: 156px;
            // height: 68px;
            background-color: white;
            border-radius: 20px;
            border-top-left-radius: 0;
            top: 110px;
            left: 186px;
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            padding: 15px;
        }
    }

    .Login-container {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        padding: 19px 20px;
        padding-top: 30px;
        background-color: white;
        position: relative;
        z-index: 10;
        top: -10px;

        .Login-illustrate {
            padding: 16px;
            margin-bottom: 20px;
            background: #F5F6F7;
            border-radius: 10px;
            font-family: PingFang SC;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
        }

        .Login-form {
            box-shadow: 4px 4px 41px 1px #0F172614;
            margin-bottom: 28px;
            z-index: 10;
            padding: 19px 20px;
            border-radius: 20px;

            .Login-form-item {
                margin-bottom: 19px;

                .Login-form-item-input {
                    padding: 10px 16px;
                    width: calc(100% - 30px);
                    border-radius: 8px;
                    background: #F5F6F7;

                    .adm-input-element {
                        font-family: PingFang SC;
                        font-size: 12px;
                        font-weight: 400;
                        --placeholder-color: var(--text-color-placholder);
                    }

                    .adm-text-area-element {
                        font-family: PingFang SC;
                        font-size: 12px;
                        font-weight: 400;
                        --placeholder-color: var(--text-color-placholder);
                    }
                }


                .Login-form-item-title {
                    font-family: PingFang SC;
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 6px;
                }
            }
        }

        .Login-edit {
            border-radius: 1000px;
        }
    }
}