@import "../../theme.scss";

.UserOverview-detail {
    height: 100%;
    color: var(--text-color-main);
    position: relative;

    .UserOverview-detail-close-btn {
        position: absolute;
        width: 28px;
        height: 28px;
        bottom: 24px;
        left: 50%;
        margin-left: -14px;
        cursor: pointer;
    }

    .UserOverview-overviews {
        height: calc(100vh - 18px - 118px - 60px);
        overflow-y: scroll;
        margin-bottom: 16px;

        .UserOverview-overview-item {
            margin-bottom: 30px;

            .title {
                font-family: PingFang SC;
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
            }

            .content {
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
            }
        }
    }

    .UserOverview-menu-btn {
        color: var(--text-color-main);
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        padding: 10px 0;
        padding-left: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .UserOverview-menu-btn-icon {
            width: 32px;
            height: 32px;
            margin-right: 11px;
        }
    }


    .UserOverview-detail-info {
        width: 100%;
        display: flex;
        margin-bottom: 30px;

        .UserOverview-detail-head {
            width: 64px;
            height: 64px;
            background-color: #bfbfbd;
            border-radius: 1000px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .UserOverview-detail-overview {
            margin-left: 19px;
            flex: 1;
            color: var(--text-color-main);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 64px;

            .UserOverview-detail-name {
                font-family: PingFang SC;
                font-size: 18px;
                font-weight: 600;
                line-height: 18px;
                margin-bottom: 8px;
            }

            .UserOverview-detail-describe {
                font-family: PingFang SC;
                font-size: 15px;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }
}

.no-border {
    text-decoration: none;
}