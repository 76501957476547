.Push {
    .Push-container {
        padding: 0 21px;

        .Push-main-title {
            font-family: PingFang SC;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
            margin-bottom: 6px;
        }

        .Push-info {
            font-family: PingFang SC;
            font-size: 10px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 16px;
        }

        .Push-content {
            padding-bottom: 50px;

            .Push-paragraph {
                margin-bottom: 16px;
                width: 100%;

                .Push-paragraph-title {
                    font-family: PingFang SC;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    margin-bottom: 10px;
                }

                .Push-paragraph-content {
                    font-family: PingFang SC;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 26px;
                }

                .Push-paragraph-image {
                    width: 100%;
                }
            }
        }

    }
}