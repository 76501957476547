@import "../../theme.scss";

.User {
    padding: 0 20px;

    .User-form {
        margin-top: 30px;
        box-shadow: 4px 4px 41px 1px #0F172614;
        padding: 19px 20px;
        margin-bottom: 28px;

        .User-form-item {
            margin-bottom: 19px;

            .User-form-item-input {
                padding: 10px 16px;
                width: calc(100% - 30px);
                border-radius: 8px;
                background: #F5F6F7;

                .adm-input-element {
                    font-family: PingFang SC;
                    font-size: 12px;
                    font-weight: 400;
                    --placeholder-color: var(--text-color-placholder);
                }

                .adm-text-area-element {
                    font-family: PingFang SC;
                    font-size: 12px;
                    font-weight: 400;
                    --placeholder-color: var(--text-color-placholder);
                }
            }


            .User-form-item-title {
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 6px;
            }
        }
    }

    .User-edit {
        width: 100%;
        border-radius: 1000px;
        margin-bottom: 28px;
    }
}