@import "../../theme.scss";

.Doctor-detail {
    height: 100%;
    color: var(--text-color-main);
    position: relative;

    .Doctor-divider {
        margin-bottom: 0;
    }

    .Doctor-detail-close-btn {
        position: absolute;
        width: 28px;
        height: 28px;
        bottom: 31px;
        left: 50%;
        margin-left: -14px;
        cursor: pointer;
    }

    .Doctor-overviews {
        position: relative;
        top: -20px;
        height: calc(100vh - 18px - 118px - 180px);
        overflow-y: scroll;

        .Doctor-overview-item {
            margin-bottom: 30px;

            .title {
                font-family: PingFang SC;
                font-size: 18px;
                font-weight: 600;
                line-height: 25px;
            }

            .content {
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
            }
        }
    }

    .Doctor-detail-info {
        width: 100%;
        display: flex;
        margin-bottom: 30px;

        .Doctor-detail-head {
            width: 118px;
            height: 118px;
            background-color: #bfbfbd;
            border-radius: 1000px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .Doctor-detail-overview {
            margin-left: 19px;
            flex: 1;


            .Doctor-detail-title {
                font-size: 24px;
                display: flex;
                align-items: center;
                color: var(--text-color-main);

                .Doctor-detail-phone {
                    width: 22px;
                    height: 22px;
                    margin-left: 12px;
                }
            }

            .Doctor-detail-introduce {
                margin-top: 5px;
                font-family: "PingFang SC";
                font-size: 10px;
                font-weight: 400;
                text-align: left;
                line-height: 19px;
                color: var(--text-color-article);
            }
        }
    }
}