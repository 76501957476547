@import "../../theme.scss";

.Doctor-chat-container {
    position: fixed;
    top: 0;
    left: -10000px;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: white;

    &.active {
        left: 0;
    }
}

.DocWrite {

    .DocWrite-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
    }

    .DocWrite-container {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        padding: 19px 20px;
        padding-top: 50px;
        background-color: white;
        position: relative;
        z-index: 10;
        margin-top: 30px;

        .DocWrite-illustrate {
            padding: 16px;
            margin-bottom: 20px;
            background: #F5F6F7;
            border-radius: 10px;
            font-family: PingFang SC;
            font-size: 15px;
            font-weight: 400;
            line-height: 21px;
        }

        .DocWrite-doc-pic {
            width: 62px;
            height: 62px;
            background-color: #bfbfbd;
            border-radius: 1000px;
            overflow: hidden;
            border: 4px solid white;
            position: absolute;
            top: -40px;
            left: 50%;
            margin-left: -40px;
        }

        .DocWrite-form {
            margin-top: 30px;
            box-shadow: 4px 4px 41px 1px #0F172614;
            margin-bottom: 28px;
            z-index: 10;
            padding: 19px 20px;
            border-radius: 20px;

            .DocWrite-form-item {
                margin-bottom: 19px;

                .DocWrite-form-item-input {
                    padding: 10px 16px;
                    width: calc(100% - 30px);
                    border-radius: 8px;
                    background: #F5F6F7;

                    .adm-input-element {
                        font-family: PingFang SC;
                        font-size: 12px;
                        font-weight: 400;
                        --placeholder-color: var(--text-color-placholder);
                    }

                    .adm-text-area-element {
                        font-family: PingFang SC;
                        font-size: 12px;
                        font-weight: 400;
                        --placeholder-color: var(--text-color-placholder);
                    }
                }


                .DocWrite-form-item-title {
                    font-family: PingFang SC;
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 6px;
                }
            }
        }
    }

    .DocWrite-edit {
        width: calc(100% - 40px);
        margin: 0 20px;
        border-radius: 1000px;
        margin-bottom: 28px;
    }
}