@import "../../theme.scss";

.block {
    display: block;
    flex: 1;
}

.Doctor-chat-container {
    position: fixed;
    top: 0;
    left: -10000px;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: white;

    &.active {
        left: 0;
    }
}

.Doctor {
    position: relative;
    height: 100vh;

    .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }

    .Doctor-pic {
        width: 248px;
        position: absolute;
        top: 10px;
    }

    .Doctor-info-container {
        position: absolute;
        top: 66px;
        width: 130px;
        float: right;
        right: 37px;

        .Doctor-title {
            font-size: 24px;
            display: flex;
            align-items: center;
            color: var(--text-color-main);

            .Doctor-phone-icon {
                width: 22px;
                height: 22px;
                margin-left: 12px;
            }
        }

        .Doctor-info {
            font-family: "PingFang SC";
            font-size: 10px;
            font-weight: 400;
            text-align: left;
            line-height: 19px;
            color: var(--text-color-article);
        }

        .Doctor-detail-btn {
            font-family: "PingFang SC";
            font-size: 10px;
            font-weight: 400;
            line-height: 19px;
            text-align: left;
            cursor: pointer;
            color: var(--text-color-main);
        }
    }

    .Doctor-push-container {
        height: calc(100vh - 356px);
        position: absolute;
        top: 268px;
        width: calc(100% - 76px);
        background: white;
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        box-shadow: -40px -60px 47px 0px rgba(0, 0, 0, 0.1411764706);
        padding: 0 38px;

        .Doctor-push-content {
            margin-top: 30px;
            width: 100%;
            height: calc(100% - 30px);
            // overflow-y: scroll;
            overflow: hidden;

            .Doctor-push-loading {
                width: 100%;
                display: flex;
                height: 300px;
                justify-content: center;
                align-items: center;
            }

            .Doctor-push-title {
                font-family: "PingFang SC";
                font-size: 18px;
                font-weight: 600;
                color: var(--text-color-main);
            }

            .Doctor-push-title-descript {
                font-family: "PingFang SC";
                font-size: 12px;
                font-weight: 300;
                margin-top: 4px;
                color: var(--text-color-descript);
            }

            .Doctor-push-list-container {
                overflow: hidden;
                height: calc(100% - 40px);

                .Doctor-push-list {
                    overflow-y: scroll;
                    height: 100%;
                    width: 100%;
                    margin-top: 20px;
                    padding: 0 6px;
                    margin-left: -4px;

                    .Doctor-push-item {
                        // margin-bottom: 16px;
                        width: calc(100% - 4px);
                        height: 226px;
                        background-color: white;
                        border-radius: 8px;
                        border: 1px solid #E6E6E6;
                        padding-top: 2px;
                        box-shadow: 0px 0px 16px 0px #00000014;

                        .Doctor-push-pic-container {
                            display: block;
                            width: calc(100% - 4px);
                            height: 136px;
                            border-radius: 8px;
                            margin: 0 auto;
                            margin-bottom: 6px;
                            overflow: hidden;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .Doctor-push-pic {
                            width: 100%;
                        }

                        .Doctor-push-texts {
                            padding: 0 16px;
                            width: calc(100% - 32px);

                            .Doctor-push-title {
                                font-family: PingFang SC;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 24px;
                                color: var(--text-color-main);
                            }

                            .Doctor-push-text {
                                font-family: PingFang SC;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 24px;
                                color: var(--text-color-article);
                                height: 30px;
                                overflow: hidden;
                            }

                            .Doctor-push-info {
                                // display: flex;
                                // justify-content: flex-start;
                                // align-items: center;
                                overflow: hidden;
                                margin-top: 8px;
                                font-family: PingFang SC;
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 24px;

                                &>div {
                                    float: left;
                                    color: var(--text-color-article);
                                }

                                .Doctor-push-info-right {
                                    float: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .Doctor-footer {
        padding: 0 20px;
        padding-top: 12px;
        display: flex;
        align-items: center;

        .Doctor-fotter-icon {
            width: 36px;
            height: 36px;
            margin-left: 12px;
        }
    }
}