.custom-popup {

    .adm-popup-body {
        height: calc(100vh - 87px);
        padding: 0 37px;
        padding-top: 37px;
        width: calc(100% - 74px);
    }

    .adm-popup-body {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
    }
}